body {
    font-family: Roboto,"Helvetica Neue",sans-serif;
}

#instance-manager {
    position: absolute;
    bottom: 0;
    top: 0;
    left: 0;
    right: 0;
    display: flex;
    flex-direction: column;
}

.ellipsis {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}

header .toolbar-icon {
    margin-left: -12px;
    margin-right: 4px;
}

header .toolbar-icon-right {
    margin-right: -12px;
    margin-left: 4px;
}

#Content-Blocker {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    z-index: 10000;
    background-color: rgba(0, 0, 0, 0.34);
}

#Content-Blocker .spinner {
    position: absolute;
    top: 50%;
    left: 50%;
}

.capitalize {
    text-transform: capitalize;
}

.react-file-reader {
    display: inline-block;
}
